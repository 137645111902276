export default class Helpers {
  prettify_price (price) {
    let priceString = price.toString();

    // Split the number into integer and decimal parts
    let parts = priceString.split('.');
    let integerPart = parts[0];
    let decimalPart = parts[1] || '';

    // Use a regular expression to insert a space every three characters in the integer part
    integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');

    // Always show 2 digits for decimal part
    decimalPart = decimalPart.padEnd(2, '0');

    // Concatenate the integer and decimal parts, if any
    let result = decimalPart ? integerPart + ',' + decimalPart.slice(0, 2) : integerPart;

    return result.replace(',00', '')
  }

  date_safari_fix (date) {
    return date.replace(/-/g, '/')
  }

  is_mobile () {
    return window.matchMedia('(max-width: 767px)').matches
  }

  format_date (date) {
    const old_date = new Date(date)
    return old_date.toLocaleDateString('fr')
  }

  line_mapper (line) {
    return `<div class="booking-details-line"><div>${line.display}</div><div class="${line.class || ''}">${line.value}</div></div>`
  }

  total_line_mapper (total) {
    return `<div class="booking-total-line"><div>Total</div><div>${total} €</div></div>`
  }

  updated_tenant_price(price) {
    return "Prix vu par le locataire : " + this.prettify_price(price) + " €"
  }

  extension_price(price) {
    return "MONTANT<br />" + this.prettify_price(price) + ' €'
  }

  get_style_numeric_value(styleElement, property) {
    return parseInt(styleElement[property]);
  }

  delete_link(period) {
    return '<a href="#" class="delete_period" data-from="' + period.from + '">' + this.trash_bin_svg() + '</a>'
  }

  trash_bin_svg () {
    return '<svg width="33" height="34" viewBox="0 0 33 34" fill="none" xmlns="http://www.w3.org/2000/svg">' +
      '<g id="Linear / Essentional, UI / Trash Bin Minimalistic">' +
        '<path id="Vector" d="M12.6096 5.64307C13.1759 4.04093 14.7038 2.89307 16.4999 2.89307C18.296 2.89307 19.8239 4.04093 20.3902 5.64307" stroke="#111010" stroke-width="1.5" stroke-linecap="round"/>' +
        '<path id="Vector_2" d="M28.1875 8.39307H4.81238" stroke="#111010" stroke-width="1.5" stroke-linecap="round"/>' +
        '<path id="Vector_3" d="M25.8959 11.8306L25.2634 21.3168C25.0201 24.9673 24.8984 26.7926 23.709 27.9053C22.5196 29.0181 20.6903 29.0181 17.0317 29.0181H15.9683C12.3097 29.0181 10.4804 29.0181 9.29104 27.9053C8.10166 26.7926 7.97997 24.9673 7.73661 21.3168L7.10419 11.8306" stroke="#111010" stroke-width="1.5" stroke-linecap="round"/>' +
        '<path id="Vector_4" d="M13.0625 15.2681L13.75 22.1431" stroke="#111010" stroke-width="1.5" stroke-linecap="round"/>' +
        '<path id="Vector_5" d="M19.9375 15.2681L19.25 22.1431" stroke="#111010" stroke-width="1.5" stroke-linecap="round"/>'  +
      '</g></svg>'
  }

  formatTooltipPrice () {
    return {
      from: function (value) {
        return parseInt(value.replace(/[\+|\s|\/|€|J]/g, +' €/j'), 0 + ' €/j')
      },
      to: function (value) {
        return (value < 1000) ? (Math.floor(value / 10) * 10 + ' €/j') : '1 000+ €/j'
      },
    }
  }

  formatDefaultTooltipMultipleValues () {
    return {
      from: function (value) {
        return parseInt(value).toString()
      },
      to: function (value) {
        return parseInt(value).toString()
      },
    }
  }

  formatDefaultTooltipOneValue () {
    return {
      to: function (value) {
        return parseInt(value).toString()
      }
    }
  }
}
