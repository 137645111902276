import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  connect () {
  }

  submit (e) {
    const form = e.target.closest('form')
    const tr = e.target.closest('tr')
    let formData = new FormData(form); //create the FormData from the form
    formData.set("call_ok", tr.querySelector('input[name="call_ok"]').checked); //add the new parameters
    formData.set("tenant_reschedule_date", tr.querySelector('input[name="tenant_reschedule_date"]').value);
    fetch(form.action, {
      method: form.method,
      body: new URLSearchParams(formData),
      headers: {
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
        'X-Requested-With': 'XMLHttpRequest'
      }}); //make the actual request
    e.preventDefault()
    window.location.reload();
  }
}
