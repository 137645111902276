// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import '@hotwired/turbo-rails'

import 'controllers'

import cookies_eu from 'cookies-eu-banner'
import map from 'underscore/modules/map.js'
import Cookies from 'js.cookie'

import '../custom/get-url-parameter.js'
// import '../custom/facebook_sdk.js.erb'

import './common_public'
import '../custom/search_calendar'

import './gems.js.erb'
import '../custom/bootstrap'

require('@rails/ujs').start()
Turbo.session.drive = false
// import "@fortawesome/fontawesome-free/js/fontawesome.min"

window.initMap = function (...args) {
  const event = document.createEvent('Events')

  event.initEvent('google-maps-callback', true, true)
  event.args = args
  window.dispatchEvent(event)
}

window.Cookies = Cookies
