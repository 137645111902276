import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  connect () {
  }

  change_month(e){
    const selectedMonth = e.target.value
    Turbo.visit(window.location.pathname + "?month=" + selectedMonth);
  }

  submit (e) {
    const form = e.target.closest('form')
    form.requestSubmit()
    form.reset()
    e.preventDefault();
    return false;
  }
}
