import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  connect () {
  }

  change_value (e) {
    const form = e.target.closest('form')
    form.requestSubmit()
  }
}
