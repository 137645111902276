import { Popover, Tooltip, Carousel, Modal, Collapse } from 'bootstrap'
import CustomEvents from './customEvents'

document.addEventListener(CustomEvents.EVENTS_KEY['turbo-refresh'], (e)=>{
  const parent = document.getElementById(e.detail.target)

  if(!parent){
    return;
  }

  const carouselTriggerList = [].slice.call(parent.querySelectorAll('.carousel'))
  const carouselList = carouselTriggerList.map(function (carouselTriggerEl) {
    return new Carousel(carouselTriggerEl)
  })
})
document.addEventListener('turbo:load', () => {
  const tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
  tooltipTriggerList.map(function (tooltipTriggerEl) {
    return new Tooltip(tooltipTriggerEl)
  })

  const carouselTriggerList = [].slice.call(document.querySelectorAll('.carousel'))
  const carouselList = carouselTriggerList.map(function (carouselTriggerEl) {
    return new Carousel(carouselTriggerEl)
  })

  const popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'))
  const popoverList = popoverTriggerList.map(function (popoverTriggerEl) {
    return new Popover(popoverTriggerEl)
  })

  const coupon_modal = document.querySelector('#couponModal');
  const coupon_modal_status = localStorage.getItem("couponModal");
  if(coupon_modal && !coupon_modal_status) {
    const myModalAlternative = new Modal(coupon_modal, {});
    myModalAlternative.show();
    coupon_modal.addEventListener('hidden.bs.modal', event => {localStorage.setItem("couponModal", "closed");})
  }

  const more_photo_links = document.querySelectorAll('.js-more-photos');
  more_photo_links.forEach(function(el){
    el.addEventListener('click', function(){
      const modal = Modal.getOrCreateInstance('#morePhotosModal')
      modal.show();
      const position = el.dataset.position
      const carousel = Carousel.getOrCreateInstance('#carouselCarDetailPhotosControlsDesktop')
      carousel.to(position)
    })
  })
})
