import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  connect () {
  }

  submit (e) {
    const form = e.target.closest('form')
    form.requestSubmit()
    form.innerHTML = ''
  }
}
